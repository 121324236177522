.main_title {
  font-family: "Rifton";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;
  text-align: center;
  padding-top: 200px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1200px) {
  .main_title {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .main_title {
    font-size: 22px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .main_title {
    font-size: 24px;
    line-height: 28.8px;
  }
}

.red_text {
  color: #f04a14;
}
.downAero_style {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 200px;
}
.cardDetailTitle {
  font-family: "Hellix-bold";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #f04a14;
}
.cardDetailTitle1 {
  font-family: "Hellix-bold";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #f04a14;
  margin-top: 60px;
}
.card_text {
  margin-top: 24px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}
@media screen and (max-width: 1440px) {
  .card_text {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 1200px) {
  .card_text {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 991px) {
  .card_text {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .card_text {
    font-size: 18px !important;
  }
}

.portfolioTitle {
  font-family: "Hellix-bold";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #f04a14;
  margin-top: 160px;
}
.card_row_col_div {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
@media screen and (max-width: 1280px) {
  .card_row_col_div {
    padding: 0px 5%;
  }
  .portfolioTitle {
    padding: 0px 5%;
  }
}
.portfolio_card {
  width: 196px;
  height: 196px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.portfolio_card:hover {
  background: #191a1c;
}
@media screen and (max-width: 1440px) {
  .portfolio_card {
    height: 146px !important;
  }
}
@media screen and (max-width: 1200px) {
  .portfolio_card {
    height: 126px !important;
  }
}
@media screen and (max-width: 991px) {
  .portfolio_card {
    height: 86px !important;
  }
}
@media screen and (max-width: 767px) {
  .portfolio_card {
    height: 196px !important;
  }
}
@media screen and (max-width: 576px) {
  .portfolio_card {
    height: 166px !important;
  }
}
@media screen and (max-width: 480px) {
  .portfolio_card {
    height: 136px !important;
  }
}
.card_first_row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0px 10%;
}
.latest_div_items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  padding: 0% 0px 2% 0px;
  align-items: center;
}
.latest_div_title {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}
@media screen and (max-width: 1200px) {
  .latest_div_title {
    font-size: 18px;
  }
}
.insights_user_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 15%;
}
@media screen and (max-width: 1200px) {
  .insights_user_div {
    width: 18%;
  }
}
@media screen and (max-width: 768px) {
  .insights_user_div {
    width: 23%;
  }
}
.username_text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-left: 6%;
}
@media screen and (max-width: 991px) {
  .username_text {
    font-size: 12px;
  }
}
.insight_user_calender_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}
.insight_date_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
}
@media screen and (max-width: 1200px) {
  .insight_date_info {
    width: 22%;
  }
}
@media screen and (max-width: 992px) {
  .insight_date_info {
    width: 25%;
  }
}
@media screen and (max-width: 768px) {
  .insight_date_info {
    width: 35%;
  }
}

.insight_date_info_text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  margin-left: 6%;
}
@media screen and (max-width: 991px) {
  .insight_date_info_text {
    font-size: 12px;
  }
}

@media screen and (max-width: 992px) {
  .card_Icon_02xc {
    margin-top: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .card_Icon_02xc {
    margin-top: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
.card_col_div {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}
@media screen and (max-width: 992px) {
  .card_col_div {
    width: 100% !important;
    margin-top: 40px;
    flex-direction: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 783px;
  }
}
.o2xCardDesign {
  height: 100%;
  background-color: #fa4d15;
  width: 52%;
  /* position: relative; */
  padding: 5%;
}
@media screen and (max-width: 992px) {
  .o2xCardDesign {
    width: 52%;
  }
  .CardDesign1 {
    width: 2%;
  }
  .CardDesign2 {
    width: 2%;
  }
  .CardDesign3 {
    width: 2%;
  }
  .CardDesign4 {
    width: 2%;
  }
  .CardDesign5 {
    width: 2%;
  }
}
@media screen and (max-width: 768px) {
  .readnow_div {
    width: 50% !important;
  }
  .o2xCardDesign {
    width: 62%;
  }
  .CardDesign1 {
    width: 2%;
  }
  .CardDesign2 {
    width: 2%;
  }
  .CardDesign3 {
    width: 2%;
  }
  .CardDesign4 {
    width: 2%;
  }
  .CardDesign5 {
    width: 2%;
  }
}
@media screen and (max-width: 576px) {
  .readnow_div {
    width: 60% !important;
  }
  .o2xCardDesign {
    width: 72%;
  }
  .CardDesign1 {
    width: 2% !important;
  }
  .CardDesign2 {
    width: 2% !important;
  }
  .CardDesign3 {
    width: 2% !important;
  }
  .CardDesign4 {
    width: 2% !important;
  }
  .CardDesign5 {
    width: 2% !important;
  }
}
.CardDesign1 {
  height: 100%;
  background-color: #fa4d15;
  width: 1.5%;
  margin-right: 1%;
}
.CardDesign2 {
  height: 100%;
  background-color: #fa4d15;
  width: 1.5%;
  margin-right: 1%;
  opacity: 0.8;
}
.CardDesign3 {
  height: 100%;
  background-color: #fa4d15;
  width: 1.5%;
  margin-right: 1%;
  opacity: 0.6;
}
.CardDesign4 {
  height: 100%;
  background-color: #fa4d15;
  width: 1.5%;
  margin-right: 1%;
  opacity: 0.4;
}
.CardDesign5 {
  height: 100%;
  background-color: #fa4d15;
  width: 1.5%;
  margin-right: 1%;
  opacity: 0.2;
}

.invest_btn {
  border: none;
  font-family: "Hellix-bold";
  font-style: normal;
  font-size: 16px;
  color: white;
  width: 160px;
  height: 48px;
  background: #f04a14;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
}
@media screen and (max-width: 767px) {
  .invest_btn {
    margin-bottom: 80px;
  }
  .home_title_div {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    background: radial-gradient(#f04a14 -160%, #0f1011 80%);
    padding: unset !important;
  }
}

.card_image_div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.o2x_card_title {
  font-family: "Hellix-bold";
  font-size: 16px;
  margin-top: 25px;
}
.o2x_card_text {
  font-family: "Hellix";
  font-size: 14px;
  margin-top: 25px;
}
.o2x_card_read_text {
  font-family: "Hellix-bold";
  font-size: 16px;
}
.readnow_div {
  display: flex;
  width: 40%;

  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  left: 10;
  right: 10;
  bottom: 4%;
}
